import { AvForm } from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import 'react-modern-drawer/dist/index.css';
import {
    Button,
    FormGroup,
    Modal
} from "reactstrap";
const CustomForm = ({ title, loading=false, position = 'right', isOpen, onDrawerClose, htmlFileds, onSubmit }) => {
    const resetValue = useRef('');
    const [checkboxData, setCheckboxData] = useState([]);
    const [errors, setErrors] = useState({});

    const handleButtonClick = (v) => setCheckboxData((prev) => prev.includes(v) ?  prev.filter((item) => item !== v): [...prev, v]);

    const handleInputChange = (fieldName, value) => {
        if (!value) {
            setErrors(prevErrors => ({ ...prevErrors, [fieldName]: 'This field is required' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
        }
    };

    const onSubmitForm = (formData) => {
        const formErrors = {};
        htmlFileds.forEach(v => {
            if (v.field.required && formData[v.field.name].value == '') {
                formErrors[v.field.name] = `${v.label} field is required`;
            }
        });

        if (Object.keys(formErrors).length === 0) {
            onSubmit(formData)
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            centered={true}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{title}</h5>
                <button
                    type="button"
                    onClick={onDrawerClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <AvForm
                        ref={resetValue}
                        className="form-horizontal"
                        onValidSubmit={(e) => onSubmitForm(e.target)}
                    >
                        {
                            htmlFileds?.map((v, i) => {
                                return (
                                    <div className="col-md-12" key={`htmlFiled_${i}`}>
                                        <FormGroup className="mb-3">
                                        {
                                            v.field.type == 'textarea' && (
                                                <>
                                                    <label className="control-label">{v.label}</label>
                                                    <textarea {...v.field} />
                                                </>
                                            )
                                        }

                                        {
                                            v.field.type == 'text' && (
                                                <>
                                                    <label className="control-label">{v.label}</label>
                                                    <input {...v.field} onChange={(e) => handleInputChange(v.field.name, e.target.value)} />
                                                    {errors[v.field.name] && <div className="text-danger">{errors[v.field.name]}</div>}
                                                </>
                                            )
                                        }
                                        {
                                            v.field.type == 'time' && (
                                                <>
                                                    <label className="control-label">{v.label}</label>
                                                    <input {...v.field} />
                                                </>
                                            )
                                        }
                                        {
                                            v.field.type == 'date' && (
                                                <>
                                                    <label className="control-label">{v.label}</label>
                                                    <input {...v.field} />
                                                </>
                                            )
                                        }
                                        {
                                            v.field.type == 'select' && (
                                                <>
                                                    <label className="control-label">{v.label}</label>
                                                    <select {...v.field}>
                                                        <>
                                                            <option value="">{v.field.placeholder}</option>
                                                            {v.field.options?.map((option, i) => <option key={i} value={option.value}>{option.label}</option>)}
                                                        </>
                                                    </select>
                                                </>
                                            )
                                        }
                                        {
                                            v.field.type == 'checkbox' && (
                                                <>
                                                    <input name={v.field.name} type="hidden" defaultValue={JSON.stringify(checkboxData)}/>
                                                    {
                                                        v.field.group?.map((gc,i) => {
                                                            return (
                                                                <div key={`gc_${i}`} className={`pointer badge bg-${ (v.field.defaultValue && JSON.parse(v.field.defaultValue)?.includes(gc)) || checkboxData.includes(gc) ? 'primary': 'secondary'} me-1`} style={{ fontSize: '16px', width: '62px'}}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`switch${i}`}
                                                                        style={{display:'none'}}
                                                                        checked={ checkboxData.includes(gc) }
                                                                        onChange={() => handleButtonClick(gc)}
                                                                    />
                                                                    <label htmlFor={`switch${i}`} className="m-0" style={{cursor: 'pointer'}} >{gc}</label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </>
                                            )
                                        }
                                        </FormGroup>
                                    </div>
                                )
                            })
                        }
                        <div className="col-md-12" key={`htmlFiled_button`}>
                            <Button color="primary w-100" type="submit" disabled={loading}>{loading ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}</Button>
                        </div>
                    </AvForm>
                </div>
            </div>
        </Modal>
    );
}

CustomForm.propTypes = {
    position: PropTypes.string,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onDrawerClose: PropTypes.func,
    htmlFileds: PropTypes.any,
    onSubmit: PropTypes.any,
    loading: PropTypes.bool
};

export default CustomForm;