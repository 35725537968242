import { AvForm } from "availity-reactstrap-validation";
import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { PANELUSER } from 'helpers/url_helper';
import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
    Button,
    Container,
    Modal
} from "reactstrap";
import Toaster from './Components/toster';
import LogoWithLoader from "components/LogoWithLoader ";

const Paneluser = () => {
    const resetValue = useRef('');
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);

    // Function to fetch data from the server based on the userID (optional)
    const fetchData = async (userID = false, isDelete=false) => {
        try {
            setIsLoading(true);
            let url = PANELUSER;
            if(userID) url = `${PANELUSER}/${userID}`
            if(isDelete) url = `${PANELUSER}/delete/${userID}`
            
            const res = await get(url);
            
            if(res.success){
                // If userID is provided, set the editData state, otherwise set the data state
                if(userID && !isDelete){
                    setEditData(res?.body);
                }else{
                    setData(res?.body);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(() => fetchData(), []);

    // Function to handle form submission (adding or editing a team)
    const handleSubmit = async (formData) => {
        // Convert form data to an object
        setIsLoading(!isLoading)
        let requestData = {};
        Object.keys(formFields).forEach((v) => {
            requestData = { ...requestData, [v]: formData[v]?.value || '' };
        });

        try {
            // Make an HTTP POST request to add or edit a team
            const res = await post(PANELUSER, requestData);

            // If the request is successful, fetch updated data and close the form
            if (res.success) {
                setEditData(null)
                setFormFields(null)
                Toaster('', {title: `${requestData?.id ? 'Update': 'Added'} successfully`})
                fetchData();
                setIsLoading(false)
                setOpenForm(!openForm);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };
    
    // Function to handle the edit action by fetching data for a specific team
    const handleEdit = (id) => fetchData(id);

    // Set default form field values when editData changes
    useEffect(() => {
        if(editData){
            formFields.id = editData?.user_id,
            formFields.username = editData?.username,
            formFields.password = editData?.password,
            formFields.role = editData?.role,
            formFields.firstname = editData?.firstname,
            formFields.lastname = editData?.lastname,
            formFields.gender = editData?.gender,
            formFields.dob = editData?.dob,
            formFields.unique_id = editData?.unique_id,
            formFields.phonenumber = editData?.phonenumber,
            formFields.address = editData?.address
            
            setOpenForm(!openForm);
        }
    },[editData])
    
    // Dummy function for handling delete action
    const handleDelete = (id) => {
        fetchData(id, true)
        Toaster('', {title: `User deleted successfully`})
    }

    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap gap-2">
                <button type="button" title="edit" className="btn-cs btn btn-warning" onClick={() => handleEdit(row.id)}><i className="bx bx-pencil "></i></button>
                <button type="button" title="delete" className="btn-cs btn btn-danger" onClick={() => handleDelete(row.unique_id)}><i className="fas fa-trash"></i></button>
            </div>
        );
    }

    const onCallbackDatatable = () => {
        setOpenForm(!openForm)
        setFormFields([])
    }

    const onDrawerCloseCallback = () => {
        setOpenForm(!openForm)
        setEditData(null)
    }

    const updateInputValue = (e) => {
        setFormFields(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Panel Users | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {
                        isLoading ? <LogoWithLoader /> :
                    <DataTable isAdd={true} title="Panel Users" tableData={data} callback={onCallbackDatatable} actions={actions} />
                    }
                </Container>
            </div>

            <Modal
                isOpen={openForm}
                centered={true}
                className="modal-xl"
            >
                <AvForm
                    ref={resetValue}
                    className="form-horizontal"
                    onValidSubmit={(e) => {
                        handleSubmit(e.target)
                    }}
                >
                    <div className="modal-body">
                            <div className="card mb-0">
                                <div className="bg-transparent border-bottom text-uppercase card-header">Login Details
                                <button
                                    type="button"
                                    onClick={onDrawerCloseCallback}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" id="user_id" name="id" defaultValue={formFields?.id} onChange={updateInputValue} />
                                    <div className="form-group row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="userName" className="control-label imp">User Name</label>
                                            <input id="userName" name="username" defaultValue={formFields?.username} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="password" className="control-label imp">Password</label>
                                            <input id="password" name="password" defaultValue={formFields?.password} onChange={updateInputValue} type="text" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="role" className="control-label imp">Role</label>
                                            <select id="role" name="role" className="form-control" defaultValue={formFields?.role} onChange={updateInputValue}>
                                                <option value="">---</option>
                                                <option value="admin">Admin</option>
                                                <option value="user">User</option>
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-0">
                                <div className="bg-transparent border-bottom text-uppercase card-header">Personal Details</div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="firstName" className="control-label imp">First Name</label>
                                            <input id="firstName" name="firstname" defaultValue={formFields?.firstname} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="lastName" className="control-label imp">Last Name</label>
                                            <input id="lastName" name="lastname" defaultValue={formFields?.lastname} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="gender" className="control-label imp">Gender</label>
                                            <select id="gender" name="gender" defaultValue={formFields?.gender} onChange={updateInputValue} className="form-select mb-3">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="unknown">Unknown</option>
                                            </select>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="dob" className="control-label">Date of birth</label>
                                            <input id="dob" name="dob" type="date" defaultValue={formFields?.dob} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="uniqueId" className="control-label imp">Unique Id</label>
                                            <input id="uniqueId" name="unique_id" defaultValue={formFields?.unique_id} readOnly={formFields?.id} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label htmlFor="phonenumber" className="control-label imp">Phone Number</label>
                                            <input id="phonenumber" name="phonenumber" defaultValue={formFields?.phonenumber} onChange={updateInputValue} type="number" className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="form-group col-md-6 mb-3">
                                            <label htmlFor="address" className="control-label imp">Address</label>
                                            <input id="address" name="address" defaultValue={formFields?.address} onChange={updateInputValue} className="form-control" />
                                            <span className="text-danger"></span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-2 col-offset-md-10" key={`htmlFiled_button`}>
                            <Button color="primary w-100" type="submit" disabled={isLoading}>{isLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}</Button>
                        </div>
                    </div>
            </AvForm>
            </Modal>

        </React.Fragment>
    )
}

export default Paneluser;