import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { DISPATCH, GETOPTIONS } from 'helpers/url_helper';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import { Col, Container, Modal, Row } from "reactstrap";
import CaseCard from './Components/caseCard';
import MyDropzone from 'helpers/MyDropzone';
import LogoWithLoader from 'components/LogoWithLoader ';

const initObj = {
    courier: "",
    _to: "",
    _number: "",
    remark: "",
    assign_to: "",
}

const SendPackage = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [addCaseModal, setAddCaseModal] = useState(false);
    const [caseObj, setCase] = useState([]);
    const [singleCase, setSingleCase] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initObj)
    const [employeeOptions, setEmployeeOptions] = useState(null)
    const [images, setImages] = useState(null)

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const options = await get(`${GETOPTIONS}`);
            const res = await get(`${DISPATCH}`);
            setData(res?.body);
            setOptions(options?.body);
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);

    useEffect(async() => {
        if(images){
            const obj = {
                _number: trackingNumber,
                docs: images
            }
            const res = await post(`${DISPATCH}/docs`, obj);
        }
    }, [images])

    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap">
                <button title="All Cases" type="button" className="btn btn-success" onClick={() => handleShowAllCases(row)}><i className="fas fa-arrow-right"></i></button>
            </div>
        );
    }

    const handleAddDispatchDetails = async() => {
        setIsLoading(!isLoading)
        const obj = {
            _number: formData?._number,
            remark: formData?.remark,
            courier: formData?.courier.value,
            _to: formData?._to.value,
            assign_to: formData?.assign_to || ''
        }
        const res = await post(`${DISPATCH}`, obj)

        if(res.success){
            setFormData(initObj);
            setIsOpenModal(false);
            fetchData();
            setIsLoading(false)
            setTrackingNumber(formData?._number);
        }
    }
    
    const filterEmployeesBasedOnLocation = (location) => {
        setEmployeeOptions(options?.emp?.filter(emp => emp.location == location))
    }

    useEffect(async() => {
        if(trackingNumber){
            setCase([])
            const res = await get(`${DISPATCH}/cases/${trackingNumber}`)
            if(res.success){
                res?.body.map((v, i) => {
                    setCase(prev => [...prev, {
                            "case_no": v.case_no,
                            "dispatch_id": v.courierno,
                            "added_at": v?.assign_at,
                            "remark": v?.remark,
                            "case_status": v?.case_status
                        }
                    ])
                })
                setImages(data?.rows?.filter(v => v._number == trackingNumber)[0]?.docs);
                setAddCaseModal(true);
            }
        }
    }, [trackingNumber])

    const handleShowAllCases = (data) => setTrackingNumber(data?._number);

    const handleAddingCases = async() => {
        const res = await post(`${DISPATCH}/cases`, singleCase)
        if(res.success){
            setCase(prev => [...prev, singleCase]);
            setSingleCase(null);
        }
    }

    const handleRemoveCase = async(i) => {
        const updatedCases = [...caseObj];
        const res = await get(`${DISPATCH}/cases/remove/${caseObj[i].case_no}`);
        if(res.success){
            updatedCases.splice(i, 1);
            setCase(updatedCases)
        }
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Package management | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {
                        isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={true} title="Send Package" tableData={data} callback={() => setIsOpenModal(true)} actions={actions}/>
                    }
                </Container>
            </div>

            {
                isOpenModal &&
                <Modal size="lg" isOpen={isOpenModal} >
                    <div className="modal-header">
                    <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> Add Dispatch Details </h5>
                    <button onClick={() => setIsOpenModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={12} className='mb-2'>
                                <label>Courier Tracking Number</label>
                                <input type="text" className="form-control" onChange={(e) => setFormData(prev => ({...prev, _number: e.target.value}))}/>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Courier</label>
                                <Select
                                    value={formData?.courier}
                                    onChange={(v) => setFormData(prev => ({...prev, courier: v}))}
                                    options={options?.courier?.map((v, i) => ({label: v.title, value: v.id}))}
                                />
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Destination</label>
                                <Select
                                    value={formData?._to}
                                    onChange={(v) => {
                                        filterEmployeesBasedOnLocation(v.value);
                                        setFormData(prev => ({...prev, _to: v}))
                                    }}
                                    options={options?.location?.map((v, i) => ({label: v.title, value: v.id}))}
                                />
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Assign To</label>
                                <select className='form-control' onChange={(e) => setFormData(prev => ({...prev, assign_to: e.target.value}))}>
                                    <option value="" key={'LOC_OP_0'}>---</option>
                                    { employeeOptions && employeeOptions?.map((v, i) => <option key={`LOE_OP_${i+1}`} value={v.unique_id}>{v.name}</option>)}
                                </select>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Remark</label>
                                <textarea className='form-control'  onChange={(e) => setFormData(prev => ({...prev, remark: e.target.value}))} defaultValue={formData?.remark} />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => setIsOpenModal(!isOpenModal)} className="btn btn-secondary" data-dismiss="modal" > Close </button>
                        <button type="button" className="btn btn-primary" disabled={isLoading} onClick={() => handleAddDispatchDetails()}>{isLoading? <i className='fas fa-spinner fa-spin'></i>: 'Submit'}</button>
                    </div>
                </Modal>
            }

            {
                addCaseModal &&
                <Modal size="xl" isOpen={addCaseModal} className="modal-fullscreen" >
                    <div className="modal-body p-0">
                        <Row className='h-100 m-1'>
                            <Col className="p-3" style={{background: '#f1f1f1'}}>
                                <Row>
                                    { caseObj.length > 0 && caseObj.map((v, i) => <Col md={3} key={`CCK_${i+1}`}><CaseCard data={v} srkey={i+1} callback={handleRemoveCase} /></Col>) }
                                </Row>
                            </Col>
                            <Col md={3}>
                                <div className='mt-2'>
                                    <label>Please Scan or Enter Case Number</label>
                                    <input type="text" placeholder='Case Number' className="form-control" value={singleCase?.case_no || ''} onChange={(e) => setSingleCase(p => ({...p, case_no: e.target.value, dispatch_id: trackingNumber, added_at: moment().format('YYYY-MM-DD HH:mm:ss')}))} />
                                    <input type="text" placeholder="Remark" className="form-control mt-2" value={singleCase?.remark || ''} onChange={(e) => setSingleCase(p => ({...p, remark: e.target.value}))} />
                                    <button type="button" className="btn btn-primary" onClick={handleAddingCases} style={{width: '100%', height: '82px', marginTop: '12px'}}>Add</button>                              
                                    <button type="button" onClick={() => {
                                        setAddCaseModal(false)
                                        setTrackingNumber(false)
                                        setCase([])
                                    }} className="btn btn-secondary mt-2" data-dismiss="modal" style={{width: '100%'}}> Close </button>
                                </div>
                                <div className='mt-2'>
                                    <MyDropzone maxFiles={3} image={images} setImage={setImages} removeImage={(data) => {
                                        const finalImgs = images.split(',').filter(v => v != data).join(',');
                                        setImages(finalImgs)
                                    }} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

export default SendPackage;