import React from 'react';
import './LogoWithLoader.css';
import logoSrc from "../assets/images/logo_loader.png"

const LogoWithLoader = () => {
  return (
    <div className="loader-container">
        <div className="logo-loader-container">
            <div className="loader active"></div>
            <img className="logo-loader" src={logoSrc} alt="Logo" />
        </div>
    </div>
  );
};

export default LogoWithLoader;
