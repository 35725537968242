import PropTypes from "prop-types"
import React, { useState } from 'react'
import Dropzone from "react-dropzone"
import { Row, Col, Progress } from 'reactstrap';
import UploadImages from "./uploadImages";

const MyDropzone = ({ image = null, removeImage = () => {}, setImage = () => {}, maxFiles = 1 }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const handleAcceptedFiles = async (acceptedFiles) => {
        setUploading(true);
        const uploadingStatus = (data) => setUploadProgress(data)

        const res = await UploadImages(acceptedFiles, uploadingStatus);
        if (res) {
            setUploading(false)
        }
        setImage(res);
    }

    return (
        <Row className='mb-3'>
            <Col md={12}>
                {
                    uploading && (
                        <Progress style={{ height: '72px', fontSize: '26px' }} color="primary" value={Math.round(uploadProgress)} animated ><i className='fas fa-location-arrow me-4'> {' '} {Math.round(uploadProgress)} % </i> </Progress>
                    )
                }
                {
                    (!image && !uploading) ?
                        <Dropzone
                            onDrop={handleAcceptedFiles}
                            maxFiles={maxFiles}
                            minFiles={1}
                            onDropRejected={(e) => { console.log(e[0].errors) }} >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                    <div className="dz-message needsclick" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dz-message needsclick">
                                            <div className="">
                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h5>Upload Category Image here or click to upload.</h5>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        :
                        !uploading && (
                            image.split(',').map((v, i) => <div key={`PIMG_${i}`} className="col-lg-2">
                                <div className="card">
                                    {
                                        i == 0 && <label>Image</label>
                                    }
                                    <img src={v} alt="document" className="img-fluid card-img-top" />
                                    <button className='btn btn-danger' onClick={() => removeImage(v)}><i className='fas fa-trash'></i></button>
                                </div>
                            </div>)
                        )
                }
            </Col>
        </Row>
    )
}

MyDropzone.propTypes = {
    removeImage: PropTypes.any,
    setImage: PropTypes.any,
    maxFiles: PropTypes.any,
    image: PropTypes.any
}

export default MyDropzone;