import DataTable from 'common/datatable';
import CustomForm from 'components/form';
import { get, post } from 'helpers/api_helper';
import { PENDING_CASES } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap";
import LogoWithLoader from 'components/LogoWithLoader ';

const Caserecive = () => {
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);

    // Function to fetch data from the server based on the caseID (optional)
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`${PENDING_CASES}`);
            setData(res?.body);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);
    

    // Function to handle form submission (adding or editing a team)
    const handleSubmit = async (formData) => {
        // Convert form data to an object
        let requestData = {};
        setIsLoading(!isLoading)
        formFields.forEach((v) => {
            requestData = { ...requestData, [v.field.name]: formData[v.field.name].value };
        });

        // If editData exists, add team_id to the requestData for editing
        if (editData) {
            requestData = { ...requestData, team_id: editData.id };
        }

        try {
            // Make an HTTP POST request to add or edit a team
            const res = await post(PICKUP, requestData);

            // If the request is successful, fetch updated data and close the form
            if (res.success) {
                fetchData();
                setIsLoading(false)
                setOpenForm(!openForm);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // Function to render action buttons in the table
    // const actions = (cell, row) => {
    //     return (
    //         <div className="d-flex flex-wrap gap-2">
    //             <button type="button" className="btn-label btn btn-success" onClick={() => handleEdit(row.id)}><i className="fas fa-check-double label-icon "></i> Accept</button>
    //         </div>
    //     );
    // }

    const onCallbackDatatable = async () => {
        setOpenForm(!openForm)
    }
    
    const onDrawerCloseCallback = () => {
        setOpenForm(!openForm)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Pending Case management | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {
                        isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={false} title="" tableData={data} callback={onCallbackDatatable} />
                    }
                </Container>
            </div>

            {
                openForm && <CustomForm title="New Case's" loading={isLoading} isOpen={openForm} onSubmit={handleSubmit} onDrawerClose={onDrawerCloseCallback} htmlFileds={formFields} />
            }

        </React.Fragment>
    )
}

export default Caserecive;