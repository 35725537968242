import React, { useState, useEffect } from 'react';
import './ZoomImage.css';
import PropTypes from "prop-types"

const HoverZoomImage = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const closeModalOnEscape = (event) => {
      if (event.key === 'Escape') {
        setModalOpen(false);
      }
    };

    const closeModalOnOutsideClick = (event) => {
      if (!event.target.closest('.modal')) {
        setModalOpen(false);
      }
    };

    if (modalOpen) {
      document.addEventListener('keydown', closeModalOnEscape);
      document.addEventListener('click', closeModalOnOutsideClick);
    }

    return () => {
      document.removeEventListener('keydown', closeModalOnEscape);
      document.removeEventListener('click', closeModalOnOutsideClick);
    };
  }, [modalOpen]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setModalOpen(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setModalOpen(false);
  };
  return (
    <div className="hover-modal-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
      <img src={src} alt={alt} className="hover-modal-image" />

      {modalOpen && (
        <div className="modalimg">
          {/* Your modal content */}
          <img src={src} alt={alt} style={{width: '320px', height: '480px'}} />
          {/* <button onClick={() => setModalOpen(false)}>Close</button> */}
        </div>
      )}
    </div>
  );
};

HoverZoomImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}


export default HoverZoomImage;
