import PropTypes from 'prop-types';
import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import { Card, CardBody, Col, Row } from "reactstrap";
import "./datatables.scss";
const { ExportCSVButton } = CSVExport;

const DataTable = ({isAdd, title, tableData=[], selectRowModes={}, callback, actions, loading = false, htmlString = false}) => {
    const rows = tableData?.rows

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    const selectRow = false;// {mode: 'checkbox'};

    const pageOptions = {
        sizePerPage: 10,
        totalSize: rows?.length, // replace later with size(customers),
        custom: true,
    }
    
    // Select All Button operation
    const { SearchBar } = Search;

    if(tableData?.attr?.length < 1){
        return 'empty';
    }
    const isHTMLString = (str) => /<[a-z][\s\S]*>/i.test(str);

    const updatedAttr = tableData?.attr?.map(item => {
        if(item.dataField === 'actions') {
            return {...item, formatter: actions }
        } else if (htmlString && htmlString?.includes(item.dataField)) {
            return {...item, formatter: (cell, row) => (isHTMLString(cell) ? <div dangerouslySetInnerHTML={{ __html: cell }} /> : cell) }
        } else {
            return item
        }
    } );

    return (
        <React.Fragment>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
{/* 
                        {
                            isAdd && (
                                <div className="mb-5">
                                    <span className="h4">{title}</span>
                                    <button className="btn btn-primary float-end" alt="add record" onClick={callback}><i className='fas fa-plus'></i> Add</button>
                                </div>
                            )
                        } */}

                        {
                            updatedAttr?.length ?
                                <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField='id'
                                columns={updatedAttr}
                                data={rows}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='id'
                                        columns={updatedAttr}
                                        data={rows}
                                        search
                                        exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                                    >
                                        {toolkitProps => (
                                        <React.Fragment>
                                            <Row className="">
                                                <Col md="8" className='text-end d-flex'>
                                                    <h4 className="h4 mt-2 ms-2">{title}</h4>
                                                    {
                                                        isAdd && 
                                                        <button className="btn btn-primary float-end ms-4" style={{height: '36px'}} title="add record" onClick={callback}><i className='fas fa-plus'></i> Add</button>
                                                    }
                                                </Col>
                                                <Col md="4" className='text-end d-flex'>
                                                    <div className="search-box me-2 w-100 d-inline-block">
                                                        <div className="position-relative">
                                                            <SearchBar {...toolkitProps.searchProps} />
                                                            {/* <i className="bx bx-search-alt search-icon" /> */}
                                                        </div>
                                                    </div>
                                                    
                                                    <ExportCSVButton
                                                        {...toolkitProps.csvProps}
                                                        className="p-0"
                                                        alt="Export Data"
                                                        style={{ marginTop: '-5px' }}
                                                    >
                                                        <i className='fs-1 text-success mdi mdi-file-excel-outline'></i>
                                                    </ExportCSVButton>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <BootstrapTable
                                                            keyField={"id"}
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            // selectRow={selectRow}
                                                            classes={"table align-middle table-nowrap"}
                                                            headerWrapperClasses={"thead-light"}
                                                            {...toolkitProps.baseProps}
                                                            {...paginationTableProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="align-items-md-center mt-30">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <PaginationListStandalone {...paginationProps} />
                                                </div>
                                            </Col>
                                            </Row>
                                        </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                                </PaginationProvider>
                            : loading ? 'Loading Data...' : 'Nodata'
                        }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

DataTable.propTypes = {
    isAdd: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    tableData: PropTypes.any.isRequired,
    selectRowModes: PropTypes.any, //{ mode: 'checkbox' }
    callback: PropTypes.any,
    actions: PropTypes.any,
    htmlString: PropTypes.any,
    loading: PropTypes.bool
};

export default DataTable
