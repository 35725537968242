export const LOGIN = "/validation/user"
export const DEPARTMENTS = "/departments"
export const DESIGNATION = "/designations"
export const TEAM = "/teams"
export const LOCATION = "/locations"
export const SHIFT = "/shifts"
export const LEAVE = "/leaves"
export const COURIER = "/courier"
export const HOLIDAYS = "/holidays"
export const EXPENSES = "/expenses"
export const EMPLOYEES = "/employees"
export const PANELUSER = "/users"
export const DEVICE = "/devices"
export const CASE = "/cases"
export const PICKUP = "/cases/pickup"
export const RECEIVEPICKUP = "/cases/send-pickup"
export const ACCEPTPICKUP = "/cases/scan-pickup"
export const CLIENT_OPTIONS = "/clients/options"
export const PENDING_CASES = "/cases/all-pending-cases"
export const DASHBOARD = "/dashboard"
export const PUNCH = "/punch"

export const ADDPICKUP = "/cases/sendpackage"
export const DISPATCH = "/cases/dispatch"
export const GETOPTIONS = "/cases/options"
export const ATTENDANCE = "/punch/attendance"