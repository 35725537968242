import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Polyline, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';
import { get } from 'helpers/api_helper'
import { Col, Container, Modal, Row } from "reactstrap";
import MetaTags from 'react-meta-tags';

const MapContainer = ({ google }) => {
    const [pathData, setPathCoordinates] = useState([]);
    const [paths, setPaths] = useState([]);

    const fetchData = async () => {
        try {
            const res = await get('/punch/live');
            if (res.success) {
                setPathCoordinates(res.body);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(Object.keys(pathData).length) {
            setPaths(Object.keys(pathData).map((key) => [key, pathData[key]]))
        }
    }, [pathData])

    if(!Object.keys(pathData).length){
        return 'data';
    }

    return (
        <Map
            google={google}
            zoom={14}
            initialCenter={{ lat: 21.1839162, lng: 72.8610854 }} // Initial center of the map
        >
            {paths?.map((employee, index) => {
                return <Polyline
                   key={index}
                   path={employee[1].paths}
                   strokeColor="#0000FF"
                   strokeOpacity={0.8}
                   strokeWeight={2}
               />
            })}

            {paths?.map((employee,  index) => {
                const name = employee[1].employee != null ? `${employee[1].employee.firstname} ${employee[1].employee.lastname}` : employee[0];
                return <Marker
                    key={index}
                    position={{ lat: employee[1].paths[0].lat, lng: employee[1].paths[0].lng }}
                    icon={{
                    url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    }}
                    title={`Ride Started - ${name} (distance traveled: ${employee[1].distance})`}
                />
            })}
            
            {paths?.map((employee, index) => {
                const name = employee[1].employee != null ? `${employee[1].employee.firstname} ${employee[1].employee.lastname}` : employee[0];
                return <Marker
                    key={index}
                    position={{ lat: employee[1].paths[employee[1].paths.length - 1].lat, lng: employee[1].paths[employee[1].paths.length - 1].lng }}
                    icon={{
                    url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                    }}
                    title={`Ending Point - ${name} (distance traveled: ${employee[1].distance})`}
                />
            })}
        </Map>
    );
};

MapContainer.propTypes = {
    google: PropTypes.any
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC4VeWGg1gWcrv2yrFkGWNQlHrFO26AZao',
  libraries: ['places']
})(MapContainer);
