import PropTypes from "prop-types"
import { cdnpost } from "./api_helper";

const UploadImages = async (acceptedFiles, uploadingStatus) => {
    const formData = new FormData();
    formData.append('org', 'ade');
    
    if(acceptedFiles.length > 1){
        acceptedFiles.forEach(file => {
            formData.append('files[]', file);
        });
    }else{
        formData.append('files[]', acceptedFiles[0]);
    }

    const res = await cdnpost(formData, uploadingStatus)

    if (res) {
        const data = JSON.parse(res?.uploaded_files)
        return data.join(',')
    }
    return ''
}

UploadImages.propTypes = {
    acceptedFiles: PropTypes.any,
    uploadingStatus: PropTypes.any
}

export default UploadImages;