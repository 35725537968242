import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ForgetPassword from "../pages/Authentication/Forget-password";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Department from "pages/Masters/departments";
import Designation from "pages/Masters/designations";
import Location from "pages/Masters/locations";
import Shift from "pages/Masters/shifts";
import Team from "pages/Masters/teams";
import Employees from "pages/employees";
import Leaves from "pages/leaves/leaves";
import Leavetypes from "pages/leaves/leavetypes";
import Paneluser from "pages/panel_user";
import Dashboard from "../pages/Dashboard/index";
import Expenses from "../pages/Expenses/expenses";
import Expensetype from "../pages/Expenses/expensetypes";
import Courier from "../pages/Masters/courier";
import DeviceManagement from "../pages/devices";
import Holidays from "../pages/leaves/holidays";
import PendingCases from "../pages/pending_cases";

import ReceivePackage from "../pages/receive-package";
import Sendpackage from "../pages/send-package";
import Travelpath from "../pages/travel-path";

import RequestPickup from "../pages/request-pickup";

// Attendance
import Attendance from '../pages/Attendance/index.jsx'

const authProtectedRoutes = [
  { path: "/masters/department", component: Department },
  { path: "/masters/designation", component: Designation },
  { path: "/masters/teams", component: Team },
  { path: "/masters/shifts", component: Shift },
  { path: "/masters/locations", component: Location },
  { path: "/masters/couriers", component: Courier },
  { path: "/panel-users", component: Paneluser },
  { path: "/employees", component: Employees },
  { path: "/leave/type", component: Leavetypes },
  { path: "/leaves", component: Leaves },
  { path: "/holidays", component: Holidays },
  { path: "/devices", component: DeviceManagement },
  { path: "/expense/types", component: Expensetype },
  { path: "/expenses", component: Expenses },
  { path: "/pm/dispatch-package", component: Sendpackage },
  // { path: "/pm/track-case", component: Case },
  { path: "/pm/pending-cases", component: PendingCases },
  { path: "/pm/receive-courier", component: ReceivePackage },
  { path: "/dashboard", component: Dashboard },
  { path: "/pm/pickup-request", component: RequestPickup },
  { path: "/profile", component: UserProfile },
  { path: "/attendance", component: Attendance },
  { path: "/live", component: Travelpath },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
]

export { authProtectedRoutes, publicRoutes };

