import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { ACCEPTPICKUP, RECEIVEPICKUP } from 'helpers/url_helper';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Col, Container, Modal, Row } from "reactstrap";
import HoverZoomImage from 'components/zoom';
import Activity from 'components/activity';
import LogoWithLoader from 'components/LogoWithLoader ';

const ReceivePackage = () => {
    const [data, setData] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isTSOpenModal, setIsTSOpenModal] = useState(false);
    const [isActivity, setIsActivity] = useState(false);
    const [trackingValue, setTrackingValue] = useState(null);
    const [caseValue, setCaseValue] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);
    const [pickups, setPickups] = useState(null);
    const [scanedPickups, setScanedPickups] = useState([]);
    const [caseNumberError, setCaseNumberError] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    // Function to fetch data from the server based on the trackingID (optional)5999884034469
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`${RECEIVEPICKUP}`);
            if(res?.body?.rows){
                setData(res?.body);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);

    const actions = (cell, row) => (
        row.isPending ?
            <div className="d-flex flex-wrap gap-2">
                <button
                    type="button"
                    disabled={!row.isPending}
                    className={`btn btn-label ${!row.isPending ? 'btn-danger' : 'btn-success'}`}
                    onClick={() => setIsTSOpenModal(true)}
                >
                    <i className="fas fa-check-double label-icon"></i> Accept
                </button>
            </div> : <div className='text-success'>Completed</div>
    )

    useEffect(async () => {
        if(trackingValue){
            try{
                const res = await get(`${ACCEPTPICKUP}/${trackingValue}`);
                if(res?.body?.length){
                    setPickups(res?.body)
                    setTrackingValue('');
                }
                
                setIsOpenModal(...data?.rows.filter(v => v._number == trackingValue))
                // setIsTSOpenModal(false)
                setErrorMsg('invalid tracking number');
            } catch (error) {
                console.error('Error fetching pickups:', error);
                // Handle error here
            }
        }
    }, [trackingValue]);

    // On Scan  of Case Number calls
    useEffect(() => {
        setCaseNumberError('');
        if (timeoutId) clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => {
            if (caseValue && caseValue?.trim() !== '') {
                const _scanedPickups = pickups?.filter(v => v.case_no === caseValue).map((pickup) => ({ ...pickup, scanDateTime: new Date().toString() }))[0];
                const pendingPickups = pickups?.filter(v => v.case_no !== caseValue);

                if(_scanedPickups !== undefined) {
                    // Update state with the updated pickups array
                    setScanedPickups([...scanedPickups, _scanedPickups]);
                    setPickups(pendingPickups);
                    setCaseValue(null);
                }else{
                    setCaseNumberError('Invalid Case Number or Case Scan Completed ')
                }
            }
        }, 600);

        setTimeoutId(newTimeoutId);

        return () => {
            if (newTimeoutId) clearTimeout(newTimeoutId);
        };
    }, [caseValue]);
    
    const handleCourierReceive = (event) => setTrackingValue(event.target.value);
    const handleCaseScan = (event) => setCaseValue(event.target.value);

    const sendScanedData = async () => {
        const temp = scanedPickups.map(v => {
            const obj = {
                id: v.id,
                datetime: moment(v.scanDateTime).format('YYYY-MM-DD HH:mm:ss'),
                reciver_remark: v.remark
            }
            return obj;
        })
        console.log(temp, pickups)
        const obj = {
            trackingId: isOpenModal?._number,
            cases: temp
        }

        const res = await post(`${ACCEPTPICKUP}/${isOpenModal?._number}`, obj);

        if(res?.body == true){
            fetchData();
            setPickups(null);
            setTimeoutId(null);
            setTrackingValue(null);
            setIsOpenModal(false);
        }
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Package management | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {/* <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{""}</h4>
                            <br />
                            <Row>
                                <Col md={3}>
                                    <label>Location</label>
                                    <select className='form-control'>
                                        <option>All</option>
                                    </select>
                                </Col>
                                <Col md={3}>
                                    <label>Employee</label>
                                    <select className='form-control'>
                                        <option>All</option>
                                    </select>
                                </Col>
                            </Row>
                        </div>
                    </div> */}
                    {
                        isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={false} title="Receive Package" tableData={data} callback={() =>{}} actions={actions}/>
                    }
                </Container>
            </div>

            <Modal size="sm" isOpen={isTSOpenModal} className="modal-md" >
                <div className="modal-header">
                    <span>Scan or Enter tracking Number</span>
                    <button onClick={() => setIsTSOpenModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                    <input type="text" className="form-control" value={trackingValue || ''} onChange={handleCourierReceive}/>
                    <span className='text-danger'>{errorMsg}</span>
                </div>
            </Modal>

            <Modal size="xl" isOpen={typeof isOpenModal == 'object'} className="modal-fullscreen" >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> Accept Package </h5>
                    <button onClick={() => setIsOpenModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body" style={{padding: '0px 10px', height: '83vh', background: '#f4f4f4' }}>
                    <Row>
                        <Col md={2} className='p-2 border'style={{overflowY: 'hidden', height: 'calc(100vh - 130px)', background: '#fff'}}>
                            <div className='text-center alert-dismissible fade show px-3 mb-0 alert alert-success alert-dismissible fade show'>
                                <h3 className='text-success'>{isOpenModal?._number}</h3>
                                <label>Please Scan or Enter Case Number</label>
                                {
                                    pickups && pickups.filter(v => v.scanDateTime == '')?.length > 0 &&
                                    <input type="text" className="form-control" value={caseValue || ''} onChange={handleCaseScan}/>
                                }
                                <span className='text-danger'>{caseNumberError}</span>
                            </div>
                            <div className="mt-2">
                                <div className="alert alert-success border shadow-none mb-2 card">
                                    <div className="p-2">
                                        <div className="d-flex">
                                            <div className="avatar-xs align-self-center me-2">
                                                <div className="avatar-title rounded bg-transparent text-success fs-2">
                                                    <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                                                </div>
                                            </div>
                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-13 text-truncate mb-1">Scaned</h5>
                                                <p className="text-muted text-truncate mb-0">{ scanedPickups?.length} Cases</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="alert alert-danger border shadow-none mb-2 card">
                                    <div className="p-2">
                                        <div className="d-flex">
                                            <div className="avatar-xs align-self-center me-2">
                                                <div className="avatar-title rounded bg-transparent text-danger fs-2">
                                                    <i className="mdi mdi-alert-rhombus"></i>
                                                </div>
                                            </div>
                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-13 text-truncate mb-1">Pending</h5>
                                                <p className="text-muted text-truncate mb-0">{pickups?.length} Cases</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    { pickups && pickups?.map((v, i) => {
                                        return <div key={`SPCL_${i}`} className="px-3">
                                            <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                    <i className="mdi mdi-circle text-danger font-size-10"></i>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-14 mb-1">{v.case_no}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    }) }

                                </div>
                            </div>
                        </Col>
                        <Col md={10}>
                            <Row className='mt-1'>
                                {
                                    scanedPickups.length > 0 && scanedPickups?.map((v, i) => {
                                        return <Col key={`SCSL_${i}`} md={3}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-15 d-flex space-between">
                                                                <a className="text-dark" href="/projects-overview/9">{v.case_no}</a>
                                                                <i title="activity" className="mdi mdi-stairs-box pointer" onClick={() => setIsActivity(v.id)}></i>
                                                            </h5>
                                                            <p className="text-muted mb-4">{v.remark}</p>
                                                            <textarea className='form-control' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 py-3 border-top">
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item me-3">
                                                            <span className="bg-success badge bg-secondary">Completed</span>
                                                        </li>
                                                        <li className="list-inline-item me-3" id="dueDate">
                                                            <i className="bx bx bx-rocket me-1"></i> {v.location}
                                                        </li>
                                                        <li className="list-inline-item me-3" id="comments">
                                                            <i className="bx bx bx-rocket me-1" style={{transform: 'scaleY(-1)'}}></i> Surat
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="px-4 py-3 border-top">
                                                    {
                                                        v.images && v.images.split(',').map((v, i) => {
                                                            return <span key={`PCIK_${i+1}`}><HoverZoomImage src={v} alt="" /></span>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>

                            {/* <table className='table table-border'>
                                <thead>
                                    <tr>
                                        <th>Package Number</th>
                                        <th>Remark</th>
                                        <th>Scan At</th>
                                        <th>Received By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pickups && pickups?.map((v, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{v.case_no}</td>
                                                    <td>
                                                        <input type="text" className='form-control' onChange={(v) => pickups[i].remark = v.target.value}/>
                                                    </td>
                                                    <td>{v.location}</td>
                                                    <td>{v?.scanDateTime}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> */}
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={() => setIsOpenModal(!isOpenModal)} className="btn btn-secondary" data-dismiss="modal" > Close </button>
                    <button type="button" className="btn btn-primary" onClick={() => sendScanedData()} disabled={pickups && pickups.filter(v => v.scanDateTime == '')?.length > 0}>Accept</button>
                </div>
            </Modal>

            {
                isActivity && <Activity callback={() => setIsActivity(false)}/>
            }
            
        </React.Fragment>
    )
}


export default ReceivePackage;