import { get } from "helpers/api_helper";
import { PUNCH } from 'helpers/url_helper';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const getDaysArray = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const daysInMonth = new Date(year, month, 0).getDate();
  const daysArray = [];

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(`${year}-${month}-${day}`);
  }

  return daysArray;
}

const Apaexlinecolumn = ({type = false}) => {
  const [series, setSeries] = useState(null);
  
  const fetchData = async () => {
    try {
        const res = await get(`${PUNCH}/${type ? 'team': 'monthly'}/${moment().startOf('month').format('YYYY-MM-DD')}/${moment().endOf('month').format('YYYY-MM-DD')}`);
        if(res?.success) {
          setSeries(res.body)
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#f46a6a"],
    xaxis: {
      categories: getDaysArray(),
    },
    yaxis: {
      title: {
        text: "employees",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter:  (val) =>  val
      },
    },
  }

  if(!series){
    return 'Loading...'
  }

  if(!series?.length){
    return 'No data'
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height={260} />
  )
}

Apaexlinecolumn.propTypes = {
  type: PropTypes.any
}

export default Apaexlinecolumn
