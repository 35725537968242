import { Modal} from "reactstrap";
import PropTypes from "prop-types"

const Activity = ({callback}) => {
    return (
        <Modal size="sm" isOpen={true} className="modal-lg" >
            <div className="modal-header">
                <span>Activity</span>
                <button type="button" className="close" onClick={() => callback(false)}><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
                <ul className="verti-timeline list-unstyled">
                    <li className="event-list false">
                        <div className="event-timeline-dot">
                            <i className="font-size-18 bx bx-right-arrow-circle"></i>
                        </div>
                        <div className="flex-shrink-0 d-flex">
                            <div className="me-3">
                                <h5 className="font-size-14">
                                    22 Nov
                                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                </h5>
                            </div>
                            <div className="flex-grow-1">
                                <div>Responded to need “Volunteer Activities”</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </Modal>
    )
}

Activity.propTypes = {
    callback: PropTypes.any
}

export default Activity;