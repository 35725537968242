import { put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { post } from 'helpers/api_helper'
import { LOGIN } from 'helpers/url_helper'
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

function* loginUser({ payload: { user, history } }) {
  try {
    if (user?.accesscode) {
      const response = yield post(LOGIN, {
        username: user.username,
        password: user.password,
        access_code: user.accesscode,
      })

      if(response?.body?.accessToken != undefined || response?.body?.refreshToken != undefined) {
        localStorage.setItem("authUser", JSON.stringify(response?.body))
        localStorage.setItem("accesstoken", response?.body?.accessToken)
        localStorage.setItem("refreshToken", response?.body?.refreshToken)
        localStorage.setItem("clientid", response?.body?.org_id)
        yield put(loginSuccess('response'))
        history.push("/dashboard")
      } else {
        yield put(apiError('Invalid Username or Password.!'))
        localStorage.clear();
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear()
    localStorage.setItem("I18N_LANGUAGE", 'en')
    localStorage.setItem("i18nextLng", 'en')
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
