import Apaexlinecolumn from "components/apaexlinecolumn";
import { get } from "helpers/api_helper";
import { DASHBOARD, DEVICE } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap";
import LiveViewMap from "./liveview_component";

const  Dashboard = () => {
  const [devices, setDevices] = useState(null);
  const [cardCounts, setCardCounts] = useState(null);

  // Function to fetch data from the server based on the deviceID (optional)
  const fetchData = async (deviceID = false, isDelete=false) => {
      try {
          const res = await get(`${DEVICE}`);
          if(res.success){
            setDevices(res?.body?.rows);
          }
          
          const count = await get(`${DASHBOARD}`);
          if(count?.success){
            setCardCounts(count?.body)
          }
          
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  // Fetch initial data when the component mounts
  useEffect(() => { fetchData(); }, []);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Field Tracking</title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <div className="col-xl-6">
              <div className="row">
                <div className="col-lg-4">
                  <div className="blog-stats-wid card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Visits</p>
                          <h5 className="mb-0">{cardCounts?.visits || 0}</h5>
                        </div>
                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-book-bookmark"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-stats-wid card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Expense Requests</p>
                          <h5 className="mb-0">{cardCounts?.expenses || 0}</h5>
                        </div>
                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-note"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-stats-wid card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total Employees</p>
                          <h5 className="mb-0">{ cardCounts?.totalemployee || 0 }</h5>
                        </div>
                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className="bx bxs-message-square-dots"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> Attendance Report </CardTitle>
                    <Apaexlinecolumn />
                  </CardBody>
                </Card>
              </div>
              <div className="row">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> Team wise Attendance Report </CardTitle>
                    <Apaexlinecolumn type={'team'}/>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="col-xl-6">
              <LiveViewMap devices={devices}/>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;
