import DataTable from 'common/datatable';
import { post, get } from 'helpers/api_helper';
import { EXPENSES } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Modal } from "reactstrap";

const initFormFields = [{
    label: 'Expense Type',
    inputTheme:'',
    field:{
        name: 'title',
        type: 'text',
        placeholder: 'please enter team name',
        required: true,
        defaultValue: '',
        id:'',
        className:'form-control',
    }
},{
    label: 'Description',
    inputTheme:'',
    field:{
        name: 'description',
        type: 'textarea',
        placeholder: 'please enter description',
        required: false,
        defaultValue: '',
        id:'',
        className:'form-control',
    }
},{
    label: 'Image Required',
    inputTheme:'',
    field:{
        name: 'is_image',
        type: 'select',
        required: true,
        defaultValue: '',
        id:'',
        options:[
            {
                "label": 'Yes',
                "value": 1
            },
            {
                "label": 'No',
                "value": 0
            }
        ],
        className:'form-control',
    }
}]

const Expenses = () => {
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenLoader, setIsOpenLoader] = useState(false);
    const [approveData, setApproveData] = useState(null);
    const [remarkError, setRemarkError] = useState(null);
    const [amountError, setAmountError] = useState(null);

    // Function to fetch data from the server based on the expenseID (optional)
    const fetchData = async (expenseID = false, isDelete=false) => {
        try {
            // Make an HTTP GET request to fetch team data
            const res = await get(`${EXPENSES}${expenseID? `/${expenseID}`:(isDelete ? '/delete' : '')}`);

            // If expenseID is provided, set the editData state, otherwise set the data state
            if(expenseID && !isDelete){
                setIsOpenLoader(false)
                setEditData(res?.body);
            }else{
                setData(res?.body);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(() => { fetchData(); }, []);

    // Function to handle the edit action by fetching data for a specific team
    const handleView = (id) => {
        setIsOpenLoader(!isOpenLoader)
        setIsOpen(!isOpen);
        fetchData(id)
    };
    
    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap gap-2">
                <button type="button" className="btn-label btn btn-primary" onClick={() => handleView(row.id)}><i className="fas fa-eye label-icon "></i> View</button>
            </div>
        );
    }

    const handleApproveOrReject = async(expenseID, type = 'app') => {
        if(!approveData?.remark){
            setRemarkError(true);
            return;
        }
        
        const res = await post(`${EXPENSES}/${type == 'app' ? 'approve' : 'reject'}/${expenseID}`, approveData);

        if(res.success){
            setApproveData(null)
            setRemarkError(false);
            setIsOpen(false);
            fetchData();
        }
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Masters - Expense Types | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    <DataTable htmlString={['file', 'status']} isAdd={false} title="Expenses" tableData={data} actions={actions} />
                </Container>
            </div>

            <Modal isOpen={isOpen} centered={true} >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Expenses</h5>
                    <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        isOpenLoader && <i className='fas fa-spinner fa-spin'></i>
                    }

                    {
                        !isOpenLoader && (
                            <div className="card">
                                <div className="border-bottom card-body">
                                    <div>
                                        <div>
                                            <h5>{editData?.employee}</h5>
                                            <p className="text-muted mb-1">{editData?.email}</p>
                                            <p className="text-muted mb-1">+91 {editData?.mobile}</p>
                                            <p className="text-muted mb-0">Id no: #{editData?.emp_code}</p>
                                        </div>
                                        {
                                            editData?.status != 'pending' &&
                                            <img src="https://www.pngall.com/wp-content/uploads/2/Approved-PNG-Free-Image.png" style={{width: '82px', position: 'absolute', top: '26px', right: '54px'}}/>
                                        }
                                    </div>
                                </div>
                                <div className="border-bottom card-body">
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div>
                                                    <p className="text-muted mb-2">Requested Amount</p>
                                                    <h5>₹ {editData?.amount?.toFixed(2)}</h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-sm-end mt-4 mt-sm-0">

                                                    {
                                                         editData?.status != 'pending' ? 
                                                         <>
                                                            <p className="text-muted mb-2">Approved Amount</p>
                                                            <h5 className='text-success'>₹ {editData?.app_amount?.toFixed(2)}</h5>
                                                         </>
                                                         : 
                                                         <>
                                                            <p className="text-muted mb-2">Please Enter Approval Amount</p>
                                                            <input type="number" placeholder="amount" className={`form-control ${amountError ? ' is-invalid': ''}`} onChange={(e) => {
                                                                const max_amount = parseFloat(editData?.amount);
                                                                if(max_amount >= parseFloat(e.target.value)){
                                                                    setApproveData(rev => ({...rev, app_amount: e.target.value}))
                                                                    setAmountError(false);
                                                                }else{
                                                                    setAmountError(true);
                                                                }
                                                            }}/>
                                                         </>
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="text-sm mt-4 mt-sm-0">
                                                    {
                                                        editData?.status != 'pending' ? (
                                                            <>
                                                                <p className="text-muted mb-2">Approval Remark</p>
                                                                <p>{editData?.remark}</p>
                                                            </>
                                                        ):
                                                        <>
                                                            <p className="text-muted mb-2">Please Enter Remark</p>
                                                            <input type="text" placeholder="remark" className={`form-control ${remarkError && !approveData?.remark  ? ' is-invalid': ''}`} onChange={(e) => setApproveData(rev => ({...rev, remark: e.target.value}))}/>
                                                        </> 
                                                    }
                                                    {/* <h5>+ $ 215.53 <span className="badge bg-success ms-1 align-bottom">+ 1.3 %</span></h5> */}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            editData?.status == 'pending' && 
                                            <div className="col-sm-12 mt-2">
                                                <button className='btn btn-primary me-3' style={{width: '36%'}} onClick={() => handleApproveOrReject(editData?.exp_id, 'app')} disabled={!approveData?.app_amount || amountError}>Approve</button>
                                                <button className='btn btn-danger' style={{width: '60%'}} onClick={() => handleApproveOrReject(editData?.exp_id, 'rej')}>Reject</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default Expenses;