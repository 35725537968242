import DataTable from 'common/datatable';
import { get } from 'helpers/api_helper';
import { DEVICE } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap";

const DeviceManagement = () => {
    const [data, setData] = useState([]);

    // Function to fetch data from the server based on the deviceID (optional)
    const fetchData = async (deviceID = false) => {
        try {
            // Make an HTTP GET request to fetch team data
            
            let res;
            if(deviceID){
                res = await get(`${DEVICE}/revoke/${deviceID}`);
            }
            res = await get(`${DEVICE}`);
            
            setData(res?.body);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(() => { fetchData(); }, []);

    // Dummy function for handling delete action
    const handleDelete = (id) => { 
        fetchData(id, true)
        Toaster('', {title: `User Deleted Successfully`})
     }

    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap gap-2">
                <button type="button" className="btn-label btn btn-danger" onClick={() => handleDelete(row.id)}><i className="fas fa-lock label-icon"></i> Delete</button>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Device Management | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    <DataTable htmlString={['is_status']} isAdd={false} title="Device Management" tableData={data}  actions={actions} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DeviceManagement;