import DataTable from 'common/datatable';
import CustomForm from 'components/form';
import { get, post } from 'helpers/api_helper';
import { COURIER } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap";

const initFormFields = [{
    label: 'Courier',
    inputTheme:'',
    field:{
        name: 'title',
        type: 'text',
        placeholder: 'please enter courier company',
        required: true,
        defaultValue: '',
        id:'',
        className:'form-control',
    }
},{
    label: 'Description',
    inputTheme:'',
    field:{
        name: 'description',
        type: 'textarea',
        placeholder: 'please enter description',
        required: false,
        defaultValue: '',
        id:'',
        className:'form-control',
    }
}]

const Courier = () => {
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [formFields, setFormFields] = useState(initFormFields);

    // Function to fetch data from the server based on the courierID (optional)
    const fetchData = async (courierID = false, isDelete=false) => {
        try {
            let url = COURIER;
            if(courierID) url = `${COURIER}/${courierID}`
            if(isDelete) url = `${COURIER}/delete/${courierID}`

            const res = await get(url);

            // If courierID is provided, set the editData state, otherwise set the data state
            if(courierID && !isDelete){
                setEditData(res?.body);
            }else{
                setData(res?.body);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(() => { fetchData(); }, []);

    // Function to handle form submission (adding or editing a team)
    const handleSubmit = async (formData) => {
        let requestData = {};
        formFields.forEach((v) => {
            requestData = { ...requestData, [v.field.name]: formData[v.field.name].value };
        });

        if (editData) {
            requestData = { ...requestData, courier_id: editData.id };
        }

        try {
            const res = await post(COURIER, requestData);

            if (res.success) {
                setFormFields(initFormFields)
                setEditData(null)
                fetchData();
                setOpenForm(!openForm);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };
    
    const handleEdit = (id) => fetchData(id);

    // Set default form field values when editData changes
    useEffect(() => {
        if(editData){
            formFields.map(v => v.field.defaultValue = editData[v.field.name])
            setOpenForm(!openForm);
        }
    },[editData])
    
    // Dummy function for handling delete action
    const handleDelete = (id) => {
        fetchData(id, true);
    }

    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap gap-2">
                <button type="button" className="btn-label btn btn-warning" onClick={() => handleEdit(row.id)}><i className="bx bx-block label-icon "></i> Edit</button>
                <button type="button" className="btn-label btn btn-danger" onClick={() => handleDelete(row.id)}><i className="fas fa-lock label-icon"></i> Delete</button>
            </div>
        );
    }

    const onCallbackDatatable = () => {
        setOpenForm(!openForm)
        formFields.map(v => v.field.defaultValue = '')
    }

    const onDrawerCloseCallback = () => {
        setOpenForm(!openForm)
        setEditData(null)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Masters - Courier | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    <DataTable isAdd={true} title="Courier" tableData={data} callback={onCallbackDatatable} actions={actions} />
                </Container>
            </div>

            {
                openForm && <CustomForm title="Add Courier" isOpen={openForm} onSubmit={handleSubmit} onDrawerClose={onDrawerCloseCallback} htmlFileds={formFields} />
            }

        </React.Fragment>
    )
}

export default Courier;