import DataTable from 'common/datatable';
import { get, post } from 'helpers/api_helper';
import { CASE, GETOPTIONS } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Col, Container, Modal, Row } from "reactstrap";
import LogoWithLoader from 'components/LogoWithLoader ';

const initObj = {
    location: "",
    employee_id: "",
    remark: "",
}

const RequestPickup = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initObj)
    const [employeeOptions, setEmployeeOptions] = useState(null)

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const options = await get(`${GETOPTIONS}`);
            const res = await get(`${CASE}/request/pickup`);
            setData(res?.body);
            setOptions(options?.body);
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch initial data when the component mounts
    useEffect(async() => fetchData(), []);

    // Function to render action buttons in the table
    const actions = (cell, row) => {
        return (
            <div className="d-flex flex-wrap">
                <button title="All Cases" type="button" className="btn btn-success" onClick={() => {}}><i className="fas fa-arrow-right"></i></button>
            </div>
        );
    }

    const handleAddRequest = async() => {
        setIsLoading(!isLoading)
        const res = await post(`${CASE}/request/pickup`, formData);
        if(res.success){
            setFormData(initObj);
            fetchData();
            setIsLoading(false)
            setIsOpenModal(false);
        }
    }

    const filterEmployeesBasedOnLocation = (location) => {
        setEmployeeOptions(options?.emp?.filter(emp => emp.location == location))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Request Pickups | Field tracking</title>
                </MetaTags>
                <Container fluid>
                    {
                         isLoading ? <LogoWithLoader /> :
                        <DataTable isAdd={true} title="Pickup Request" tableData={data} callback={() => setIsOpenModal(true)} actions={actions}/>
                    }
                </Container>
            </div>

            {
                isOpenModal &&
                <Modal size="lg" isOpen={isOpenModal} >
                    <div className="modal-header">
                    <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel"> Add Pickup Request </h5>
                    <button onClick={() => setIsOpenModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close" ><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col md={12} className='mb-2'>
                                <label>Location</label>
                                <select className='form-control' onChange={(e) => {
                                    filterEmployeesBasedOnLocation(e.target.value);
                                    setFormData(prev => ({...prev, location: e.target.value}))
                                }}>
                                    <option value="" key={'LOC_OP_0'}>---</option>
                                    { options?.location?.length > 0 && options?.location?.map((v, i) => <option key={`LOE_OP_${i+1}`} value={v.id}>{v.title}</option>)}
                                </select>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Assign To</label>
                                <select className='form-control' onChange={(e) => setFormData(prev => ({...prev, employee_id: e.target.value}))}>
                                    <option value="" key={'LOC_OP_0'}>---</option>
                                    { employeeOptions && employeeOptions?.map((v, i) => <option key={`LOE_OP_${i+1}`} value={v.unique_id}>{v.name}</option>)}
                                </select>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <label>Remark</label>
                                <textarea className='form-control'  onChange={(e) => setFormData(prev => ({...prev, remark: e.target.value}))} defaultValue={formData?.remark} />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => setIsOpenModal(!isOpenModal)} className="btn btn-secondary" data-dismiss="modal" > Close </button>
                        <button type="button" className="btn btn-primary" disabled={isLoading} onClick={() => handleAddRequest()}>{isLoading? <i className='fas -fa-spinner fa-spin'></i>: 'Submit'} </button>
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

export default RequestPickup;