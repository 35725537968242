import PropTypes from "prop-types";

const CaseCard = ({data, srkey, callback= () => {}}) => {
    
    let dataLabel = <span className="badge bg-warning">Pending</span>;

    if(data.case_status == 1){
        dataLabel = <span className="badge bg-info">Assigned</span>
    } else if(data.case_status == 2){
        dataLabel = <span className="badge bg-success">Delivered</span>
    }

    // return <div key={`COL_${srkey}`} className="toast fade show me-1" role="alert">
    //         <div className="toast-header">
    //             <strong className="me-auto" style={{fontSize:"16px"}}>{data.case_no} - {data.case_status}</strong>
    //             {dataLabel}
    //             <button type="button" className="btn-close" aria-label="Close" onClick={() => callback(i)}></button>
    //         </div>
    //         <div className="toast-body">Remark: <br /><span style={{fontSize:"14px"}}>{data.remark}</span></div>
    //     </div>

    return (
        <div key={`COL_${srkey}`} className="col-xl-12 col-md-6">
            <div className="card">
                <div className="card-body">
                    <button style={{right: '18px'}} className="position-absolute btn btn-danger"><i className="bx bx-trash"></i></button>
                    <div className="d-flex">
                        <div className="flex-grow-1 overflow-hidden">
                            <p className="text-muted">Case Number</p>
                            <h4 className="text-truncate font-size-15"><a className="text-dark" href="/candidate-overview">{data.case_no}</a></h4>
                            <span style={{fontSize:"16px", right: '18px'}}>{dataLabel}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CaseCard.propTypes = {
    data: PropTypes.object,
    srkey: PropTypes.number,
    callback: PropTypes.any,
}

export default CaseCard;