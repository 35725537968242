import axios from "axios";


//pass new generated access token here
let token = localStorage?.getItem('accesstoken')

const axiosApi = axios.create({
  baseURL:  process.env.REACT_APP_APIURL || "http://127.0.0.1:4000/v1/"
  // baseURL: "http://127.0.0.1:4001/api/v1/"
})

if(token){
  axiosApi.defaults.headers.common["Authorization"] = token
}

let isRefreshing = false;

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const isTokenExpired = error.response && (error.response.status === 401 || error.response.status === 403);
    if (isTokenExpired && !originalRequest._retry) {
      if (isRefreshing) {
        // localStorage.clear();
        window.location.href = '/'
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        return refreshTokenAndRetry(originalRequest);
      } else {
        isRefreshing = true;
        return refreshTokenAndRetry(originalRequest);
      }
    }

    // For other errors, reject the promise
    return Promise.reject(error);
  }
)

async function refreshTokenAndRetry(originalRequest) {
  try {
    const newToken = await refreshToken();
    originalRequest._retry = true;
    axiosApi.defaults.headers.common['Authorization'] = newToken;
    originalRequest.headers.Authorization = newToken;
    return axiosApi(originalRequest);
  } catch (refreshError) {
    return Promise.reject(refreshError);
  } finally {
    isRefreshing = false;
  }
}

export async function cdnpost(data, setUploadProgress) {
  return axiosApi
    .post('https://cdn.keepsmiles.in/upload.php', data, { headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setUploadProgress(progress);
    }})
    .then(response => response.data)
}

async function refreshToken() {
  try {
    const response = await post('validation/getaccesstoken', { "refreshtoken": localStorage?.getItem('refreshToken') });
    if (response?.success) {
      const localToken = response?.body;
      localStorage.setItem("accesstoken", localToken?.accessToken);
      localStorage.setItem('refreshToken', localToken?.refreshToken);
      token = localToken.accessToken;
      axiosApi.defaults.headers.common['Authorization'] = localToken?.accessToken;
      return localToken.accessToken;
    }
  } catch (error) {
    window.location.href = '/logout'
    return false;
  }
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
