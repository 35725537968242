import React from "react"
import { Col, Container, Row } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>V 1.0 - {new Date().getFullYear()} © Advance Dental Export.</Col>
            <Col md={6}><div className="text-sm-end d-none d-sm-block">Develop & Maintain by <a rel="noreferrer" href="http://catchysystem.com" target="_blank">Catchysystem Technologies Pvt. Ltd.</a></div></Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
