import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from 'constants/google-service';

// Initialize Firebase 
const app = initializeApp(firebaseConfig, 'pushnotifications');
const messaging = getMessaging(app);


export const onMessageListener = () =>
    new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        resolve(payload);
    });
});

export const getdeviceToken = async() => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging,  {
                vapidKey: 'BNEqXvLFOB2Q0kFGVw_9rPd64_BNTqMZsQiRboC9sYPiO4mHv8_CHxojtPuMcbpNGfjkyizVcqhXSLkxlcB9k3g',
            });
            console.log("FCM Token:", token);
        } else {
            console.log("Permission denied for notifications");
        }
    } catch (error) {
        console.log("Error getting FCM token:", error);
    }
}