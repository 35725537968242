    import PropTypes from "prop-types"
    import React, { useEffect, useState } from "react"
    import MetaTags from "react-meta-tags"
    import { Card, CardBody, Col, Container, Row } from "reactstrap"
    import { Link, withRouter } from "react-router-dom"
    import { AvField, AvForm } from "availity-reactstrap-validation"

    // import images
    import logo from "assets/images/logo.svg"
    import profile from "assets/images/profile-img.png"
    import { get, post } from 'helpers/api_helper'

    //Import config

    const Login = props => {
    const [accessCode, setAccessCode] = useState(null);

    useEffect(async() => {
        const res = await get('organizations/validate');
        if(res?.success){
            setAccessCode(res?.body?.access_code)
        }
    }, [])

    // handleValidSubmit
    const handleValidSubmit = async(event, values) => {
        const res = await post('/reset-password', values);
        if(res?.success){
            console.log(res)            
        }
    }

    return (
        <React.Fragment>
        <MetaTags>
            <title>Forget Password | ADE Tracking</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark"><i className="fas fa-home h2" /></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
            <Container>
            <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                    <Row>
                        <Col xs={10}>
                        <div className="text-primary p-4">
                            <h5 className="text-primary">Forget Password !</h5>
                            <p> A reset link will be sent to your registered email address and mobile number.</p>
                        </div>
                        </Col>
                        <Col className="col-2 align-self-end">
                            <img src={profile} alt="bg_image" style={{ position: 'absolute', top: '-128px', right: '22px', opacity: '0.4', width: '180px'}} />
                        </Col>
                    </Row>
                    </div>
                    <CardBody className="pt-0">
                    <div>
                        <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                            />
                            </span>
                        </div>
                        </Link>
                    </div>
                    <div className="p-2">
                        <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                        }}
                        >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <AvField
                            name="accesscode"
                            type="hidden"
                            value={accessCode?.toString()}
                        />

                        <div className="mb-3">
                            <AvField
                            name="username"
                            label="Username"
                            value=""
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            required
                            />
                        </div>

                        <div className="mt-3 d-grid">
                            <button className="btn btn-primary btn-block" type="submit" > Reset Password </button>
                        </div>
                        <div className="mt-4 text-center">
                            <i className="mdi mdi-arrow-left me-1" /> Remembered your password? <Link to="/login" className="text-primary">Login</Link>
                        </div>
                        </AvForm>
                    </div>
                    </CardBody>
                </Card>
                <div className="mt-5 text-center">
                    <p>developed & maintained by Catchysystem Technologies Pvt. Ltd.</p>
                </div>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    )
    }

export default withRouter(Login)

Login.propTypes = {
    history: PropTypes.object,
}
